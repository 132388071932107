import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css"; // Assurez-vous d'importer correctement les styles du popup
import { likePost, unlikePost } from "../../actions/post.actions";
import { UidContext } from "../AppContext"; // Assurez-vous d'importer correctement le contexte UidContext

const LikeButton = ({ post }) => {
  const [liked, setLiked] = useState(false);
  const uid = useContext(UidContext);
  const dispatch = useDispatch();
  // Mettez à jour l'état "liked" en fonction de la présence de l'uid dans les likers du post
  useEffect(() => {
    if (post.likers.includes(uid)) setLiked(true);
    else setLiked(false);
  }, [uid, post.likers]);

  // Fonction pour gérer l'action "like"
  const like = () => {
    dispatch(likePost(post._id, uid));
    setLiked(true);
  };

  // Fonction pour gérer l'action "unlike"
  const unlike = () => {
    dispatch(unlikePost(post._id, uid));
    setLiked(false);
  };

  return (
    <div className="like-container">
      {uid === null && (
        <Popup
          trigger={<img src="./img/icons/heart.svg" alt="like" />}
          position="bottom center" // "position" doit être une chaîne de caractères, pas un tableau
          closeOnDocumentClick
        >
          <div>Connectez-vous pour aimer un post !</div>
        </Popup>
      )}
      {uid && liked === false && (
        <img src="./img/icons/heart.svg" alt="like" onClick={like} />
      )}
      {uid && liked === true && (
        <img src="./img/icons/heart-filled.svg" alt="unlike" onClick={unlike} />
      )}
      <span>{post.likers.length}</span>
    </div>
  );
};

export default LikeButton;
