import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../../pages/Home";
import Profil from "../../pages/Profil";
import Trending from "../../pages/Trending";
import Navbar from "../Navbar";

// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();

const routeContainer = () => {
  return (
    // <Router history={history}>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/profil" exact Component={Profil} />
        <Route path="/trending" exact Component={Trending} />
        {/* <Route path="/" element={<Navigate to="/" />} />{" "} */}
      </Routes>
    </Router>
  );
};

export default routeContainer;
