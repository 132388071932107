import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadPicture } from "../../actions/user.actions";

const UploadImg = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer);

  const handlePicture = async (e) => {
    e.preventDefault();

    if (!file) {
      setError("Veuillez sélectionner un fichier image.");
      return;
    }

    try {
      const data = new FormData();
      data.append("name", userData.pseudo);
      data.append("userId", userData._id);
      data.append("file", file);

      await dispatch(uploadPicture(data, userData._id));

      // Réinitialiser le champ de fichier après l'envoi réussi
      setFile(null);
      setError(""); // Effacer les messages d'erreur
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'image :", error);
      setError("Une erreur s'est produite lors de l'envoi de l'image.");
    }
  };

  return (
    <form onSubmit={handlePicture} className="upload-pic">
      <label htmlFor="file">Changer d'image</label>
      <input
        type="file"
        id="file"
        name="file"
        accept=".jpg, .jpeg, .png"
        onChange={(e) => {
          setFile(e.target.files[0]);
          setError(""); // Effacer les messages d'erreur lorsque l'utilisateur sélectionne un nouveau fichier
        }}
      />
      {error && <div className="error-message">{error}</div>}
      <br />
      <input type="submit" value="Envoyer" />
    </form>
  );
};

export default UploadImg;
