import axios from "axios";

// const errorLogger = require("../../src/errorLogger");

export const GET_USER = "GET_USER";
export const UPLOAD_PICTURE = "UPLOAD_PICTURE";
export const UPDATE_BIO = "UPDATE_BIO";
export const FOLLOW_USER = "FOLLOW_USER";
export const UNFOLLOW_USER = "UNFOLLOW_USER";

export const GET_USER_ERRORS = "GET_USER_ERRORS";

// Définir l'action getUser
export const getUser = (uid) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/${uid}`)
      .then((res) => {
        dispatch({ type: GET_USER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// upload de l'image
// export const uploadPicture = (data, id) => {
//   return (dispatch) => {
//     return axios
//       .post(`${process.env.REACT_APP_API_URL}/api/user/upload`, data)
//       .then((res) => {
//         return axios
//           .get(`${process.env.REACT_APP_API_URL}/api/user/${id}`)
//           .then((res) => {
//             dispatch({ type: UPLOAD_PICTURE, payload: res.data.picture });
//           });
//       })
//       .catch((err) => console.log(err));
//   };
// };

export const uploadPicture = (data, id) => {
  return async (dispatch) => {
    console.log("Début de l'action uploadPicture...");

    try {
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/upload`,
        data
      );
      if (uploadResponse.data.errors) {
        dispatch({
          type: GET_USER_ERRORS,
          payload: uploadResponse.data.errors,
        });
      } else {
        dispatch({ type: GET_USER_ERRORS, payload: "" });
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/${id}`
        );
        console.log("Données de l'utilisateur récupérées avec succès.");
        dispatch({ type: UPLOAD_PICTURE, payload: userResponse.data.picture });
      }
    } catch (err) {
      console.log("Une erreur s'est produite pendant l'action uploadPicture :");
      console.log(err);
    }
  };
};




export const updateBio = (userId, bio) => {
  return async (dispatch) => {
    console.log("Avant la requête Axios : userId =", userId, "bio =", bio);

    return axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/user/` + userId,
      data: { bio },
    })
      .then((res) => {
        console.log("Réponse de la requête Axios :", res.data);
        dispatch({ type: UPDATE_BIO, payload: bio });
      })
      .catch((err) => {
        console.error("Erreur lors de la requête Axios :", err);
      });
  };
};

export const followUser = (followerId, idToFollow) => {
  return async (dispatch) => {
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/api/user/follow/` + followerId,
      data: { idToFollow },
    })
      .then((res) => {
        console.log(res.data); // Log the response data
        dispatch({ type: FOLLOW_USER, payload: { idToFollow } });
      })
      .catch((err) => console.log(err));
  };
};

export const unfollowUser = (followerId, idToUnFollow) => {
  return async (dispatch) => {
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/api/user/unfollow/` + followerId,
      data: { idToUnFollow },
    })
      .then((res) => {
        dispatch({ type: UNFOLLOW_USER, payload: { idToUnFollow } });
      })
      .catch((err) => console.log(err));
    // .catch((err) => errorLogger.error(err.message))
  };
};
